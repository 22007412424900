<template>
  <div>
    <article class="panel is-primary" v-if="examDetails && !finished">
      <p class="panel-heading">
        {{ examDetails.examVersion.exam.name }}
      </p>
      <quiz-info :exam-details="examDetails"></quiz-info>
      <div class="panel-block is-size-10 has-background-info has-text-info-light" v-if="examDetails.userAnswers && examDetails.userAnswers.length > 0">
        <b-progress :value="currentQuestion" :max="examDetails.userAnswers.length" show-value>{{currentQuestion}} z {{ examDetails.userAnswers.length }}</b-progress>
      </div>
      <div class="panel-block is-size-10 has-background-info has-text-info-light">
        <quiz-countdown :seconds="examDetails.remainingSeconds" @timeout="finishExam()"></quiz-countdown>
      </div>
      <quiz-question v-for="(question, key) in examDetails.userAnswers" :key="question.id"
                     v-if="examDetails.started && !examDetails.finished && key==questionNumber"
                     :questionNumber="questionNumber"
                     :question="question"
                     :token="token"
                     :last="key==examDetails.userAnswers.length-1"
                     @changeQuestion="changeQuestion"
                     @changeUnsaved="changeUnsaved"
                     @doFinishExam="finishExam" 
      ></quiz-question>
      <quiz-begin-button :exam-details="examDetails"></quiz-begin-button>
    </article>
    <article class="panel is-primary" v-else-if="finished">
      <p class="panel-heading">
         {{ examDetails.examVersion.name }}
      </p>
      <div class="panel-block is-size-3 has-background-success has-text-info-light">
        Egzamin {{ examDetails.examVersion.name }} został ukończony.
      </div>
      <span v-if="examDetails.finished">
        Wynik: {{examDetails.passed?'zaliczony':'nie zaliczony'}}
      </span>
      <span v-else>
        <quiz-countdown label="wynik poznasz za:" :seconds="examDetails.remainingSeconds"></quiz-countdown>
      </span>
    </article>
    <b-loading :is-full-page="true" v-model="loading"></b-loading>
  </div>
</template>
<script>
import EXAM_DETAILS_QUERY from '@/graphql/examDetails.query.gql';
import QuizQuestion from "@/components/QuizQuestion";
import QuizInfo from "@/components/QuizInfo";
import QuizCountdown from "@/components/QuizCountdown";
import QuizBeginButton from "@/components/QuizBeginButton";
import QuizFinishButton from "@/components/QuizFinishButton";

export default {
  components: {QuizFinishButton, QuizBeginButton, QuizCountdown, QuizInfo, QuizQuestion},
  data() {
    return {
      finished: false,
      isLoading: true,
      questionNumber: 0,
      hasUnsaved: false,
    }
  },
  methods: {
    finishExam() {
      this.finished = true;
    },
    changeUnsaved(value) {
      this.hasUnsaved = value;
    },
    changeQuestion(value) {
      this.questionNumber += value;
      if (this.questionNumber >= this.examDetails.userAnswers.length - 1) {
        this.questionNumber = this.examDetails.userAnswers.length - 1;
      }
      if (this.questionNumber < 0) {
        this.questionNumber = 0;
      }
    },

  },
  computed: {
    currentQuestion() {
      return this.questionNumber + 1;
    },
    loading() {
      return !this.examDetails;
    }
  },
  comments: {QuizQuestion},
  apollo: {
    examDetails: {
      query: EXAM_DETAILS_QUERY,
      variables() {
        return {
          token: this.token
        }
      },
      update(data) {
        return data.exam;
      },
    }
  },
  props: {
    token: {
      type: String,
      required: true
    }
  }
};
</script>
<style>
.progress-wrapper{
  width: 100%;
}
</style>