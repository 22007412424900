<template>
  <section class="panel-block is-size-10">
    <b-button
        class="is-warning"
        @click="finishExam()"
        :loading="loading"
    >Zakończ egzamin
    </b-button>
  </section>
</template>

<script>

export default {
  name: "QuizFinishButton",
  data() {
    return {
      loading: false
    };
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  methods: {
    finishExam() {
      this.$buefy.dialog.confirm({
        title: 'Zakończ egzamin',
        message: `Czy na penwo chcesz zakończyć egzamin?`,
        cancelText: 'Nie',
        confirmText: 'Tak',
        type: 'is-success',
        onConfirm: () => this.$emit('finishExam')
      })
    },
  }
};
</script>