<template>
  <div>
    <p class="panel-block is-size-4 has-background-primary-light">
      {{ question.question.name }}
    </p>
    <p class="panel-block is-size-4" v-if="question.question.file && question.file.webPath">
      <span class="image">
        <img :src="'http://localhost/'+question.question.file.webPath" class="question-image"/>
      </span>
    </p>
    <section class="panel-block">
      <form>
        <b-field v-for="answer in question.question.answers">
          <b-radio v-model="selectedAnswer"
                   size="is-medium"
                   :native-value="answer.id">
            {{ answer.name }}
          </b-radio>
        </b-field>
      </form>
    </section>
    <section class="panel-block buttons">
      <b-button class="is-outlined" :loading="loading" :disabled="false" @click="$emit('changeQuestion',-1)">
        Wstecz
      </b-button>
      <b-button class="is-primary is-outlined" v-if="!last" :loading="loading" :disabled="!selectedAnswer"
                @click="doAnswer()">
        Dalej
      </b-button>
      <b-button class="is-primary is-outlined" v-else :loading="loading" :disabled="!selectedAnswer"
                @click="doAnswer()">
        Zapisz
      </b-button>
    </section>
    <quiz-finish-button
        @finishExam="finishExam"
        :token="examDetails.token"
        v-if="canFinish"
    ></quiz-finish-button>
  </div>
</template>

<script>
import EXAM_DETAILS_QUERY from "@/graphql/examDetails.query.gql";
import ANSWER_MUTATION from "@/graphql/sendAnswer.mutation.gql";
import QuizFinishButton from "@/components/QuizFinishButton";

export default {
  name: 'QuizQuestion',
  components: {QuizFinishButton},
  data() {
    return {
      loading: false,
      selectedAnswer: null,
      hasUnsaved: false,
    };
  },
  props: {
    token: {
      type: String,
      required: true
    },
    last: {
      type: Boolean,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  methods: {
    canFinish() {
      if (this.hasUnsaved) {
        return false;
      }
      if (!this.examDetails.userAnswers) {
        return false;
      }
      let answeredQuestions = 0;
      for (const answers of this.examDetails.userAnswers) {
        if (answers.questionAnswers.length > 0) {
          answeredQuestions++;
        }
      }

      return answeredQuestions === this.examDetails.userAnswers.length;
    },
    resolveSelectedAnswer() {
      if (this.question.questionAnswers.length === 0) {
        return [];
      }
      return this.question.questionAnswers.map((answer) => answer.id);
    },

    finishExam() {
      this.doAnswer().then(() => {
        this.$emit('doFinishExam');
      });
    },

    doAnswer() {
      this.loading = true;
      const promise = this.$apollo.mutate({
        mutation: ANSWER_MUTATION,
        variables: {
          token: this.token,
          answerId: this.selectedAnswer
        },
        update: (store, {data: {exam}}) => {
          store.writeQuery({
            query: EXAM_DETAILS_QUERY,
            variables: {token: this.examDetails.token},
            data: {exam: exam}
          });
        }
      });
      promise.then(() => {
        this.loading = false;
        this.hasUnsaved = false;
        this.$emit('changeUnsaved', false);
        this.$emit('changeQuestion', 1)
      });
      return promise;
    }
  },
  mounted() {
    if (this.resolveSelectedAnswer().length > 0) {
      this.selectedAnswer = this.resolveSelectedAnswer()[0];
    }
  },
  apollo: {
    examDetails: {
      query: EXAM_DETAILS_QUERY,
      variables() {
        return {
          token: this.token
        }
      },
      update: (data) => {
        return data.exam;
      },
    }
  }
}
</script>
<style scoped>
.question-image {
  width: auto;
  max-height: 300px;
}

.buttons .button {
  margin-right: 1rem;
}
</style>