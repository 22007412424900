<template>
  <span v-if="countDown > 0">
    <span v-if="countDown > 3*60">
      {{ label }} <strong>{{ countDown / 60 | minutes }} minut</strong>
    </span>
    <span v-else type="is-warning">
      {{ label }} <strong>{{ countDown }} sekund</strong>
    </span>
  </span>
</template>

<script>

export default {
  name: "QuizCountdown",
  data() {
    return {
      countDown: null,
    }
  },
  filters: {
    minutes(value) {
      return Math.floor(value);
    }
  },
  props: {
    label: {
      type: String,
      default: "Do końca zostało ci:"
    },
    seconds: {
      required: true,
      type: Number
    }
  },
  mounted() {
    this.countDownTimer(this.seconds);
  },
  methods: {
    countDownTimer(remainingSeconds) {
      if (remainingSeconds) {
        this.countDown = remainingSeconds - 10;
      }
      if (this.countDown !== null && this.countDown <= 0) {
        this.$emit('timeout');
      }
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    }
  },
};
</script>