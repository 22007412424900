<template>
  <section class="panel-block is-size-10 has-background-info has-text-primary-light">
      Na wypełnienie egzaminu masz {{ examDetails.examVersion.maxTime }} minut od jego rozpoczęcia. Egzamin składa się z {{examDetails.examVersion.questionCount}} pytań.
      To daje {{ examDetails.examVersion.maxTime / examDetails.examVersion.questionCount }} minut na pytanie.
  </section>
</template>

<script>
export default {
  name:"QuizInfo",
  props: {
    examDetails: {
      type: Object,
      required: true
    }
  }
};
</script>