<template>
  <section class="panel-block is-size-10">
    <b-button
        v-if="examDetails.canBeStarted"
        class="is-warning"
        @click="askNameSurname()"
        :loading="loading"
    >Rozpocznij
    </b-button>
    <div v-else-if="!examDetails.started">Egzamin nie może być rozpoczęty</div>
  </section>
</template>

<script>
import BEGIN_EXAM_MUTATION from '@/graphql/beginExam.mutation.gql';
import EXAM_DETAILS_QUERY from '@/graphql/examDetails.query.gql';

export default {
  name: "QuizBeginButton",
  data() {
    return {
      loading: false
    };
  },
  props: {
    examDetails: {
      type: Object,
      required: true
    }
  },
  methods: {
    askNameSurname(){
      this.$buefy.dialog.confirm({
        title: 'Rozpocznij egzamin',
        message: `Zaraz rozpocznie się egzamin. Upewnij się że to Twoje dane: <pre>${this.examDetails.participant.lastName} ${this.examDetails.participant.firstName}</pre>`,
        cancelText: 'Nie',
        confirmText: 'Tak',
        type: 'is-success',
        onConfirm: () => this.beginExam()
      })
    },
    beginExam() {
      this.loading = true;
      this.$apollo.mutate({
        mutation: BEGIN_EXAM_MUTATION,
        variables: {
          token: this.examDetails.token
        },
        update: (store, {data: {exam}}) => {
          store.writeQuery({
            query: EXAM_DETAILS_QUERY,
            variables: {token: this.examDetails.token},
            data: {exam: exam}
          });
        }
      }).then(() => {
        this.loading = false;
      });
    },
  }
};
</script>